import './fotos.css';

// Import all pictures from fotos folder and set a random image
const images = [];

const image = () => {
    return images[Math.floor(Math.random() * images.length)];
}

const importAll = (r) => {
    r.keys().forEach(element => {
        images.push(r(element));
    });
}
importAll(require.context('../img/fotos/', false, /\.(png|jpg|jpeg|svg|webp)$/));

const randomImage = image();

function Fotos() {
    return (
        <div className="flex-fill" style={{
            backgroundImage: `url(${randomImage.default})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        }}>
        </div >
    );
}

export default Fotos;