function Beneficios() {
    return (
        <div className="m-3">
            <p>Son muchos los beneficios que nos puede reportar la interacción con los caballos. Entre otros podemos destacar</p>
            <ul>
                <li>Mejora del tono muscular</li>
                <li>Ampliación y mejora de las estrategias sociales</li>
                <li>Reeducación neuronal para mejorar el mecanismo de la marcha</li>
                <li>Aumento de los tiempos de atención y de la memoria</li>
                <li>Aumento de la autoestima (empoderamiento)</li>
            </ul>
        </div>
    );
}

export default Beneficios;