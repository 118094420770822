import foto from '../img/fotos/211020.equinotep.5.jpg';

function Tipos() {
    return (<div>
        <p>Según las necesidades de cada persona, el caballo nos puede ayudar de diferentes maneras y aprovechar las múltiples facetas de sus capacidades.</p>
        <div class="row">
            <div class="col">
                <img src={foto} alt="foto" />
            </div>
            <div class="col">
                <h3>Hipoterapia</h3>
                <p>Las personas que trabajan de esta forma se benefician del calor y movimiento del caballo, produciendo cambios en los patrones neuronales y consiguiendo mejoras físicas en sus movimientos y fuerza corporal.</p>
                <h3>Equitación Terapéutica</h3>
                <p>Cuando el jinete o amazona tienen posibilidad de control sobre su postura y movimientos, podemos aprovechar los beneficios a nivel psicomotor.</p>
                <h3>Volteo Terapéutico</h3>
                <p> Se puede trabajar complementaria y alternativamente con la Equitación terapeútica. Así mejoraremos la fuerza y el equilibrio de la persona usuaria.</p>
                <h3>Sessiones en Grupo</h3>
                <p>De esta forma aprovechamos la faceta social de nuestros compañeros, los caballos. Ellos nos enseñarán a regular y adaptar nuestras reacciones para obtener su incondicional amistad y mejorar nuestras relaciones sociales.</p>
            </div>
        </div>
    </div>)
}
export default Tipos;
