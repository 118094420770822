import './contacta.css';
import Phone from '../img/phone.svg';
import Mail from '../img/mail.svg';
import WhatsApp from '../img/whatsapp.svg';

const weekDays = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes"]

function Contacta() {
    return (<div>
        <p>La Asociación EQUINOTEP fué dada de alta en el registro de asociaciones del País Vasco con el nº AS/A/21533/2018 el 20 de Febrero de 2018.</p>
        <p>Puedes ponerte en contacto con nostros por teléfono o por correo electrónico.</p>
        <table>
            <tbody>
                <tr>
                    <td><img src={Phone} alt="telefono" /></td>
                    <td>606019325</td>
                </tr>
                <tr>
                    <td><img src={Mail} alt="correo" /></td>
                    <td><a href="mailto:info@equinotep.com">info@equinotep.com</a></td>
                </tr>
                <tr>
                    <td><img src={WhatsApp} alt="whatsapp" /></td>
                    <td>
                        <a href="https://api.whatsapp.com/send?phone=34606019325&text=%C2%A1Hola%21.%20He%20visto%20tu%20web.%20%C2%BFHablamos%3F">
                            Equinotep WhatsApp</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <hr />
        <table>
            <thead>
                <tr>
                    <td>
                        <h3>Horario</h3>
                    </td>
                </tr>
            </thead>
            <tbody>
                {weekDays.map(w => {
                    return (
                        <tr key={w}>
                            <td>{w}</td>
                            <td>10:00</td>
                            <td>-</td>
                            <td>20:00</td>
                        </tr>
                    )
                })}
                <tr><td colSpan="3">Para los fines de semana, es necesario concertar una cita.</td></tr>
            </tbody>
        </table>
        <hr />
        <p>Podrás encontrarnos en</p>
        <h4>Caballerizas Bikuña</h4>
        <p>Calle Arbe 9, 01207, Vicuña, Álava</p>
        <iframe
            title="Vicuña"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2925.089013191683!2d-2.3351140485585784!3d42.84985361191852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd5022855f1d067d%3A0x920461b18ff68c93!2sCaballerizas%20Biku%C3%B1a!5e0!3m2!1ses!2ses!4v1662886874920!5m2!1ses!2ses"
            width="450"
            height="450"
            allowfullscreen=""
            loading="lazy">
        </iframe>
    </div>)
}
export default Contacta;