import Fotos from './fotos';
import './inicio.css';

function Inicio() {
    return (
        <div className="m-3 d-flex flex-column h-100" >
            <div className="d-flex quote">
                <span>&ldquo;</span>
                <p>Que toda aquella persona que quiera acercarse al caballo y beneficiarse de la interacción con él pueda hacerlo, independientemente de sus capacidades o dificultades.</p>
                <span>&rdquo;</span>
            </div>
            <hr />
            <Fotos className="flex-fill" />
            <hr />
        </div>
    );
}

export default Inicio;