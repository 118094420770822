import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Inicio from './components/inicio';
import Beneficios from './components/beneficios';
import Tipos from './components/tipos';
import Nosotros from './components/nosotros';
import Contacta from './components/contacta';
import Header from './components/header';
import Footer from './components/footer';

function App() {
  return (
    <div className="d-flex flex-column h-100">
      <Header />
      <div className="container flex-fill">
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Inicio} />
            <Route exact path="/beneficios" component={Beneficios} />
            <Route exact path="/tipos" component={Tipos} />
            <Route exact path="/nosotros" component={Nosotros} />
            <Route exact path="/contacta" component={Contacta} />
          </Switch>
        </BrowserRouter>
      </div>
      <Footer />
    </div>
  );
}

export default App;
