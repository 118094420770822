import './footer.css';
import Mail from '../img/mail.svg';
import Blogger from '../img/blogger.svg';
import Facebook from '../img/facebook.svg';
import Twitter from '../img/twitter.svg';
import WhatsApp from '../img/whatsapp.svg';

function Footer() {
    return (
        <footer className="footer mt-auto py-3 bg-light">
            <div className="container d-flex">
                <span className="flex-fill">Equinotep. Caballos que ayudan.</span>
                <div>
                    <a href="mailto:info@equinotep.com">
                        <img src={Mail} title="Manda un correo" alt="mail" />
                    </a>
                    <a target="_blank" rel="noreferrer" href="http://riendasyestribos.blogspot.com/">
                        <img src={Blogger} title="Visita mi blog" alt="blogger" />
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/equinotep/">
                        <img src={Facebook} title="Mi página en Facebook" alt="facebook" />
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://twitter.com/search?q=equinotep&src=typed_query">
                        <img src={Twitter} title="Twitter" alt="twitter" />
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=34606019325&text=%C2%A1Hola%21.%20He%20visto%20tu%20web.%20%C2%BFHablamos%3F">
                        <img src={WhatsApp} alt="WhatsApp" /></a>
                </div>
            </div>
        </footer>)
}
export default Footer;